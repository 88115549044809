import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import Preloader from "./utils/Preloader";
import { BrowserRouter as Router } from "react-router-dom";

const AsyncApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<Preloader />}>
        <AsyncApp />
      </Suspense>
    </Router>
  </React.StrictMode>
);
